
.box-shadow-menu {
	position: relative;
	width: 28px;
	height: 27px;
	cursor: pointer;
}
.box-shadow-menu::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0.25em;
	width: 28px;
	height: 4px;
	background: grey;
	box-shadow: 
		0 8px 0 0 grey,
		0 16px 0 0 grey;
}
.box-shadow-menu:hover::before {
	background: rgb(197, 197, 197);
	box-shadow: 
	0 8px 0 0 rgb(197, 197, 197),
	0 16px 0 0 rgb(197, 197, 197);
}
