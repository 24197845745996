
@font-face {
    font-family: 'impact';
    /* src: url('./fonts/greatvibes-regular-webfont.woff2') format('woff2'),
         url('./fonts/greatvibes-regular-webfont.woff') format('woff'); */
    src: url('./fonts/unicode.impact-webfont.woff2') format('woff2'),
         url('./fonts/unicode.impact-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
